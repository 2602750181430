import mutateOrders from '@/api/graphql/mutationsJS/mutateOrders'
import mutateShipments from '@/api/graphql/mutationsJS/mutateShipments'
import { SALES_ORDER_STATUS, SHIPMENT_ORDER_STATUS } from '@/models/ExtraBackendModels'
export const taskList = {
  mixins: [mutateOrders, mutateShipments],
  computed: {
    user () {
      return this.$store.state.profile.user
    }
  },
  methods: {

    /**
     * Moves the status of an order
     * @param order
     * @param status
     * @return {Promise<String>} assignee
     */
    async moveSalesOrder (order, status) {
      let user = this.user.id
      if (status === SALES_ORDER_STATUS.CREATED) {
        user = null
      } else if (status === SALES_ORDER_STATUS.BROUGHT_TO_SHIPPING) {
        user = null
        await this.moveShipment(order.shipmentId, '57')
      }
      try {
        await this.updateSale({ id: order.id, assigned_to_id: user, status_id: status })
        return user ? this.user.initials : 'Claim'
      } catch (error) {
      }
    },

    /**
     * Moves the status of a shipment
     * @param {String} shipmentId
     * @param {String} status
     * @return {Promise<String>} assignee
     */
    async moveShipment (shipmentId, status) {
      const user = this.user.id
      // if (`${status}` === SHIPMENT_ORDER_STATUS.TO_PACK) {
      //   user = null
      // }
      try {
        await this.updateShipmentOrderStatus(shipmentId, status, user)
        return user ? this.user.initials : 'Claim'
      } catch (error) {
      }
    }
  }
}
