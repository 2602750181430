<template>
  <v-card>
    <v-card-title class="purple">
      <h3 class="white--text"><slot name="title">Printer Selection</slot></h3>
    </v-card-title>
    <v-card-text>
      <v-card-text>
        <v-container>
          <v-layout justify-center align-center>
            <v-flex>
              <printer-select
                :printer="printer"
                @updated="$emit('updated', { value: $event.value })"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <slot name="actions"/>
    </v-card-actions>
  </v-card>
</template>

<script>
import printers from '@/components/autocompletes/printers'

export default {
  name: 'PrintDialog',
  components: {
    'printer-select': printers
  },
  data () {
    return {
      printer: 0
    }
  }
}
</script>
