<template>
  <v-container fluid pa-0>
    <filter-bar :loading="loading" @searched="filter" @refresh="$apollo.queries.orders.refresh()"/>
    <v-container fluid>
      <v-layout
        row align-start justify-start nowrap style="overflow-x: scroll; overflow-y: visible;"
        class="py-2 white"
      >
        <list
          v-for="list in listConfig"
          :name="list.name"
          :orders="filterList(orders, list.status)"
          :status="list.status"
          :key="list.name"
          @dragged="moveOrder"
        >
          <template #card="{ order }">
            <processing-card :order="order" @list="printPickList"/>
          </template>
        </list>
      </v-layout>
    </v-container>
    <v-dialog
      v-model="printDialog.show"
      lazy
      persistent
      max-width="500px"
    >
      <print-dialog @updated="printer = $event.value">
        <template #title>
          {{ printDialog.title }}
        </template>
        <template #actions>
          <cancel-button @click="resetPrintDialog" class="mr-2"/>
          <submit-button
            :loading="printing"
            :disabled="!printer"
            @click="printDialog.handler()"
          >
            <template #label>Print</template>
          </submit-button>
        </template>
      </print-dialog>
    </v-dialog>
  </v-container>
</template>

<script>
import list from '@/components/boards/list.vue'
import filterBar from '@/components/bars/filterbar.vue'
import { taskList } from '@/components/mixins/taskList'
import { GET_ORDERS_TO_PROCESS } from '@/api/graphql/Constants/Orders'
import { SALES_ORDER_STATUS } from '@/models/ExtraBackendModels'
import { MapShipmentOrderFieldsForProcessingCards, ScrollHorizontalWithWheel } from '@/lib/helpers'
import card from '@/components/boards/card'
import PrintDialog from '@/components/dialogs/PrintDialog'
import SubmitButton from '@/components/buttons/SubmitButton'
import CancelButton from '@/components/buttons/CancelButton'
import { PrintPickListForSt } from '@/api/graphql/Constants/printNode'
export default {
  name: 'processing',
  mixins: [taskList],
  components: {
    list,
    filterBar,
    'processing-card': card,
    'print-dialog': PrintDialog,
    'submit-button': SubmitButton,
    'cancel-button': CancelButton
  },
  computed: {
    user () {
      return this.$store.state.profile.user
    },

    sortConfig () {
      return this.$store.state.orders.listSortConfig
    }
  },
  data () {
    return {
      orders: [],
      listConfig: [
        { name: 'To Pull', status: SALES_ORDER_STATUS.TO_PICK },
        { name: 'Pulling...', status: SALES_ORDER_STATUS.PICKING },
        { name: 'To Test/In Testing', status: SALES_ORDER_STATUS.WAITING_FOR_TESTING },
        { name: 'Brought to shipping', status: SALES_ORDER_STATUS.BROUGHT_TO_SHIPPING }
      ],
      searchable: '',
      loading: false,

      printer: 0,
      printing: false,
      printDialog: {
        title: '',
        show: false,
        handler: null
      }
    }
  },
  apollo: {
    orders: {
      query: GET_ORDERS_TO_PROCESS,
      variables: {
        input: {
          filters: [
            {
              key: 'status__id__gt',
              value: 7
            },
            {
              key: 'attached_purchase__isnull',
              value: true
            },
            {
              key: 'attached_sale__isnull',
              value: false,
              or: {
                key: 'attached_purchase_rma__isnull',
                value: false,
                or: {
                  key: 'attached_rma__isnull',
                  value: false
                }
              }
            }
          ]
        }
      },
      update (data) {
        this.refresh = false
        return MapShipmentOrderFieldsForProcessingCards(data.orders)
      },
      fetchPolicy: 'no-cache',
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  methods: {
    filterList (orders, status) {
      // return a list that is filtered by status and by searchables.searched
      const list = []
      let checker = false

      for (const order of orders) {
        if (order.status.toString() === status) {
          for (const key in order) {
            if ((String(order[key]).toLowerCase()).indexOf(this.searchable.toLowerCase()) !== -1) {
              checker = true
            }
          }
          if (checker) {
            list.push(order)
            checker = false
          }
        }
      }
      return list.sort((a, b) => {
        return this.sortConfig.descending ? a[this.sortConfig.sortBy.field] - b[this.sortConfig.sortBy.field] : b[this.sortConfig.sortBy.field] - a[this.sortConfig.sortBy.field]
      })
    },

    filter (searchable) {
      this.searchable = searchable
    },

    resetPrintDialog () {
      this.printDialog.show = false
      this.printDialog.title = ''
      this.printDialog.handler = null
    },

    printPickList (event) {
      const order = event.value
      this.printDialog.title = 'Print Pick List for Order: ' + order.id
      this.printDialog.handler = async () => {
        if (this.printer) {
          this.printing = true
          try {
            await PrintPickListForSt(order, this.printer)
          } catch (e) {
            this.$store.dispatch('notifications/createSnackbar', {
              message: e.message || 'Could not print pick list.',
              color: 'error',
              timeout: 4000
            })
          } finally {
            this.printing = false
          }
        } else {
          this.$store.dispatch('notifications/createSnackbar', {
            message: 'Printer could not be used',
            color: 'error',
            timeout: 4000
          })
        }
      }
      this.printDialog.show = true
    },

    async moveOrder (order, status) {
      for (let i = 0; i < this.orders.length; i++) {
        if (this.orders[i].id === order.id) {
          const st = this.orders[i]
          st.status = status
          st.assignee = await this.moveSalesOrder(st, status)
          break
        }
      }
    }
  },
  mounted () {
    this.$apollo.queries.orders.startPolling(15000)
  },
  beforeDestroy () {
    this.$apollo.queries.orders.stopPolling()
  }
}
</script>
